import instance from '@/api/auth'
import { getHeaders } from '@/api/utils'
import { AuthenticateResponse, ValidateResponse } from './type'

export const authenticateUser = async ({
    user,
    password,
}: {
    user: string
    password: string
}) => {
    const response = await instance.post<AuthenticateResponse>(
        'access/v2/profile/user',
        {
            usuario: user,
            senha: password,
        },
        { headers: getHeaders() }
    )
    return response.data
}

export const validateUserToken = async () => {
    const response = await instance.post<ValidateResponse>(
        '/tokenvalidation',
        {},
        {
            headers: {
                'x-funcionalidade': 'Carregamento inicial',
                'x-acao': 'Carregamento inicial',
                ...getHeaders(),
            },
        }
    )
    return response.data
}

export const recoverPassword = async (email: string) => {
    const response = await instance.post('/access/restorepassword', {
        email,
    })

    return response.data
}

export const changePassword = async (password: string) => {
    const response = await instance.post(
        '/access/changepassword',
        {
            password,
        },
        { headers: getHeaders() }
    )

    return response.data
}
